@import "styles/app.scss";

// TODO: вернуть серый фон когда будут отзывы
.share {
  padding-top: 60px;
  position: relative;
  // background: $colorLightGray;
  background: $colorWhite;
  padding-bottom: 40px;

  &_title {
    margin-bottom: 20px;
    max-width: 343px;
    margin-inline: auto;
  }
  &__mediaCards {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
  }

  &__mediaPhoto,
  &__mediaVideo {
    gap: 8px;
    display: grid;
    grid-template-columns: 343px;
    grid-template-rows: auto;
    span {
      position: absolute;
    }
  }

  &__mediaPhoto {
    img {
        aspect-ratio: 343 / 309;
    }
  }

  &__mediaVideo {
    img {
        aspect-ratio: 343 / 190;
    }
  }
}

@include mediaMd {
  .share {
    &_title {
        max-width: 656px;
    }
    &__mediaVideo {
      grid-template: 190px 190px / 324px 324px;
      > a > div {
        max-height: 100%;
      }
      img {
        object-fit: cover;
        aspect-ratio: 324 / 190;
      }
    }
    &__mediaPhoto {
      grid-template: 190px 190px / 211px 211px 211px;
      > a > div {
        max-height: 100%;
      }
      img {
        min-width: 100%;
        object-fit: cover;
        aspect-ratio: 211 / 190;
      }
    }
  }
}

@include mediaGt {
  .share {
    background: $colorLightGray;
    &_title {
      margin-bottom: 40px;
      margin-inline: 0;
      max-width: 70%;
    }
  }
}

@include mediaEx {
  .share {
    padding-bottom: 80px;

    &__mediaCards {
      flex-direction: row;
    }

    &__mediaVideo {
      gap: 16px;
    }
    &__mediaPhoto {
      gap: 16px;
    }
  }
}

@include mediaXx {
  .share {
    &__mediaVideo {
      gap: 20px;
      grid-template: 230px 230px / 392px 392px;
      > a > div {
        max-height: 100%;
      }
      img {
        object-fit: cover;
        aspect-ratio: 392 / 230;
      }
    }
    &__mediaPhoto {
      gap: 20px;
      grid-template: 230px 230px / 255px 255px 255px;
      > a > div {
        max-height: 100%;
      }
      img {
        min-width: 100%;
        object-fit: cover;
        aspect-ratio: 255 / 230;
      }
    }
  }
}


