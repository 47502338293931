@import "styles/app.scss";

.club {
  max-height: 500px;
  min-height: 500px;
  overflow: hidden;
  position: relative;
  height: 100%;
  max-width: 1345px;
  width: 100%;
  margin: 0 auto;
  padding-top: 110px;
  padding-bottom: 110px;
  background-color: #f0f0f2;
  z-index: 1;

  display: flex;
  justify-content: center;

  @include mediaXx {
    max-width: 1625px;
  }

  &__withoutButton {
    padding-block: 60px;
    min-height: 320px;
    height: 320px;
    position: relative;

    @include mediaLg {
      padding-block: 110px;
      height: 100%;
      min-height: 500px;
    }

    &_ellipse {
      position: absolute;
      border-radius: 854px;
      width: 854px;
      height: 854px;
      background: rgba(255, 254, 250, 0.70);
      mix-blend-mode: soft-light;
      filter: blur(200px);
      z-index: 1;

      @include mediaLg {
        display: none;
      }
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    gap: 15px;
    max-width: 510px;
    padding-inline: 49px;
    @include mediaMd{
        max-width: 600px;
    }
    @include mediaGt {
      padding: 0;
      max-width: 787px;
    }

    &_withoutButton {
      max-width: 233px;
      padding-block: 60px;
      padding-inline: 0;
      justify-content: flex-start;
  
      @include mediaLg {
        padding: 0;
        justify-content: center;
        max-width: 510px;
      }

      h2 {
        max-width: 200px;

        @include mediaSm {
          max-width: 510px;
    
        }
      }
    }
  }

 
  &__leaves {
    width: 100%;
    height: 100%;
    inset: 0;
    position: absolute;
    z-index: 2;
    display: none;
    @include mediaSm {
      display: block;
    }
  }
  &__leavesMobile {
    width: 100%;
    height: 100%;
    inset: 0;
    position: absolute;
    z-index: 2;
    display: block;
    @include mediaSm {
      display: none;
    }
  }
  &__bg {
    opacity: 0.7;
    mix-blend-mode: overlay;
  }
  &__button {
    margin-top: 10px;
  }
}
